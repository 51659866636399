import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {
  FaInstagram,
  FaLinkedinIn,
  FaRegCalendarAlt,
  FaRegClock,
  FaRegCommentDots,
} from "react-icons/fa"
import Img from "gatsby-image"
import SEO from "../components/SEO"
import { DiscussionEmbed } from "disqus-react"
import { CommentCount } from "disqus-react"
import StyledHeading from "../components/StyledHeading"

const Template = ({ data }) => {
  const disqusShortname = process.env.GATSBY_DISQUS_NAME
  const disqusConfig = {
    identifier: data.markdownRemark.id,
    title: data.markdownRemark.frontmatter.title,
    url: `https://www.imgerd.nl/blog/${data.markdownRemark.frontmatter.slug}/`,
  }

  return (
    <Layout>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        image={
          data.markdownRemark.frontmatter.largeImage.childImageSharp.fluid.src
        }
      />
      <div className="container max-w-screen-md px-6 mx-auto mt-8 md:mt-16 lg:px-0">
        <article>
          <div className="flex justify-center mt-20 sm:mt-32">
            <StyledHeading
              tag="h1"
              text={data.markdownRemark.frontmatter.title}
              size="text-3xl"
            />
          </div>
          <div className="mt-10 text-sm text-center">
            <FaRegCalendarAlt className="inline" />{" "}
            {data.markdownRemark.frontmatter.date} |{" "}
            <FaRegClock className="inline" />{" "}
            {data.markdownRemark.fields.readingTime.text} |{" "}
            <FaRegCommentDots className="inline" />{" "}
            <CommentCount shortname={disqusShortname} config={disqusConfig} />
          </div>
          <div
            className="mt-10 prose max-w-none"
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </article>
        <hr className="mt-10 border-primary" />
        <div className="flex mt-10">
          <Img
            fixed={data.file.childImageSharp.fixed}
            className="flex-none rounded-full"
          />
          <div className="ml-10">
            <div className="font-bold">{data.site.siteMetadata.author}</div>
            <p className="mt-5 text-sm leading-loose">
              Like je dit en ben je hip and happening? Volg me op Instagram.
              Vond je dit interessant en ben je zakelijk? Volg me op LinkedIn.
              Zo blijf je lekker op de hoogte.
            </p>
            <div className="mt-5 text-sm">
              <a
                href="https://www.instagram.com/imgerd/"
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <FaInstagram className="inline text-2xl" />
              </a>
              <a
                href="https://www.linkedin.com/in/imgerd-friso-479a61125/"
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <FaLinkedinIn className="inline ml-3 text-2xl" />
              </a>
            </div>
          </div>
        </div>
        <DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
          className="mt-20"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        description
        date(formatString: "DD/MM/YYYY")
        slug
        largeImage {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      html
      fields {
        readingTime {
          text
        }
      }
    }
    file(relativePath: { eq: "media/IMG_4018-320x320.jpg" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Template
